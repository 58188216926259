
export const english = {

    //menu
    home: "Home",
    dashboard: "Dashboard",
    data: "data",
    orders: "Orders",
    delivery_order: "Delivery Order",
    invoice: "Invoice",
    master_data: "Master Data",
    client: "Client",
    supplier: "Supplier",
    product: "Product",
    category: "Category",
    uom: "Uom",
    inventory: "Inventory",
    product_inventory: "Product inventory",
    inventory_transactions: "Inventory Transactions",
    configuration: "Configuration",
    settings: "Settings",
    currency: "Currency",
    terms: "Terms",
    term_type: "Term Type",
    company: "Company",
    company_profile: "Company Profile",
    company_users: "Company Users",
    admin_area: "Admin area",

    //system admin menu, no translation required
    system_admin_area: "System Admin Area",
    tenant_maintenance: "Tenant Maintenance",
    invoice_do: "Invoice/Do",
    tenant_management: "Tenant Management",
    tenant: "Tenant",
    users: "Users",
    api_configuration: "API Configuration",
    subscription_package: "Subscription Package",
    servicegroup: "Servicegroup",
    services: "Services",
    scheduler: "Scheduler",
    scheduler_log: "Scheduler Log",

    //header
    all_notification: "All Notification",
    hi: "Hi",
    account_settings: "Account Settings",
    logout: "Logout",
    support_sent_message: "Support request sent, we will reply to your email as soonest.",
    support_request: "Support Request",
    support_request_info: "Let us know how we can support your request; welcome all the suggestions too.",
    more_contents_error: "Please enter more contents",


    //generic
    ok: "Ok",
    to: "To",
    yes: "Yes",
    no: "No",
    create: "Create",
    cancel: "Cancel",
    delete: "Delete",
    update: "Update",
    unknow_error: "Error occurs, please contact support.",
    data_failed_to_load: "Data failed to load.",
    please_confirm: "Please confirm.",
    invalid_email_format: "Invalid Email Format.",
    rows_per_page: "Rows per page",
    record_deleted: "Record Deleted.",
    record_created: "Record Created.",
    record_updated: "Record Updated.",
    record_delete_message: "You are about to delete a record, please confirm.",
    saved: "saved",


    //create tenant
    company_created: "Company created!",
    company_created_prompt: "You have created your company, please login again to load company profile.",
    create_company: "Create a Company",
    create_company_first_step: "First step of your digitization",
    create_company_fill_info: "Fill in all information",
    create_company_in_eceipt: "Create your company in Eceipt",
    create_company_manage_order: "Manage orders",
    create_company_manage_inventory: "Manage inventory",
    create_company_save_cost: "Save Cost",
    company_name: "Company Name",
    tenant_code_exist: "Tenant Code Exist",
    company_code: "Company Code",
    company_introduction: "Company Introduction",
    representative: "Representative",
    business_registration_number: "Business Registration Number",
    contact_email: "Contact Email",
    contact_number: "Contact Number",
    fax: "Fax",
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    address_line_3: "Address Line 3",
    address_line_4: "Address Line 4",
    country: "Country",
    postcode: "Postcode",

    //table column name
    id: "Id",
    nr: "Nr",
    "on hand": "on hand",
    name: "Name",
    description: "Description",
    quantity: "Quantity",
    unit: "unit",
    remark: "remark",
    date: "Date",
    message: "message",
    total: "Total",
    unitprice: "unitprice",
    safetystocklevel: "S_Stock",
    safetystocklevelInfo: "Put Safty Stock of more than Zero to display in the dashboard.",
    type: "type",
    shortname: "short name",
    movement: "Movement",
    completed_on: "on",
    do: "Do",
    by: "By",
    firstname: "firstname",
    lastname: "lastname",
    roles: "roles",
    provider: "provider",
    mode: "mode",
    amount: "amount",
    onhand: "on hand",
    reserved: "reserved",
    in_transit: "in trans",


    //dashboard
    overdue: "overdue",
    outstanding_invoice: "Outstanding Invoice",
    dashboard_of: "of",
    outstanding_delivery_order: "Outstanding Delivery Order",
    invoice_history: "Invoice History",
    order_count: "Order Count",
    order_amount: "Order Amount",
    delivery_order_history: "Delivery Order History",
    normal: "Normal",
    low: "Low",
    nostock: "No Stock",
    code: "Code",
    status: "Status",
    outstanding_purchase_order: "Outstanding Purchase Order",

    //orser status
    ALL: "ALL",
    all: "ALL",
    DRAFT: 'DRAFT',
    GENERATED: 'GENERATED',
    INVOICED: 'INVOICED',
    SETTLED: 'SETTLED',
    CANCELLED: 'CANCELLED',
    TRANSIT: 'TRANSIT',
    DELIVERED: 'DELIVERED',
    ORDER: "ORDER",
    order: "ORDER",
    ADJUSTMENT: "ADJUSTMENT",
    APPROVED: "APPROVED",
    OPEN: "OPEN",
    RECEIVED: "RECEIVED",
    APPROVE: "APPROVE",
    FINALISED: "FINALISED",
    ADJUSTED: "ADJUSTED",
    COMPLETED: "COMPLETED",
    CREATED: "CREATED",
    INTRANSIT: "INTRANSIT",
    TRANSACTED: "TRANSACTED",
    PURCHASE: 'PURCHASE',
    SALES: 'SALES',
    DEFAULT: 'DEFAULT',
    VOID: 'VOID',

    //orders
    transaction_success: "Transaction Success.",
    generate_pdf_success: "PDF generated successfully.",
    resend_email_success: "Email resent successfully.",
    confirm_delivery_order1: "You are about to confirm deliver goods to client.",
    confirm_delivery_order2: "Once confirmed, you wont be able to change the contents in the future.",
    confirm_resend_email1: "You are about to resend PDF order to client.",
    confirm_resend_email2: "Email will be send to client email address, please input below if you want to add more email address. ( seperate by , )",
    confirm_goods_received: "Please confirm all goods received.",
    ship: "Ship",
    ship_send_email: "Ship & Send Email",
    email: "Email",
    send: "Send",
    received: "Received",
    cancel_order_warning: "You are about to cancel the order, once order cancelled, it cannot be revert.",
    cancel_order: "Cancel Order",
    manage_delivery_order: "Manage Delivery Order",
    search_order_number: "Search Order Number",

    order_number: "Order Number",
    order_to: "To",
    delivery_date: "Delivery Date",

    view_pdf: "View PDF",
    pdf_generating: "PDF Generating",
    regenerate_pdf_message: "Click to regenerate if no PDF generated",
    action: "Action",
    edit: "Edit",
    invoice_send_email: "Invoie & Send Email",
    revise: "Revise",
    receive: "Receive",
    update_linked_invoice: "Update Linked Invoice",
    data_preview_alert: "Data preview only, PDF will have a different format",
    from: "from",
    phone: "Phone",
    brn: "BRN",
    attn_to: "Attention To",
    invoice_number: "Invoice Number",
    total_quantity: "Total Quantity",
    loading_company_info: "Loading Company Info",
    loading_existing_client: "Loading Existing Client",
    loading_existing_open_invoice: "Loading Existing open invoice",
    do_saved: "Delivery Order Saved",
    fill_all_field_warning: "Please fill in all field to continue",
    finalise_do_message: "You are finalizing this delivery order, please confirm if all information is correct before finalise this order.",
    generate_order_number_message: "An order number will be generated and will not be able to reassigned.",
    finalize: "Finalize",
    create_delivery_order: "Create Delivery Order",
    linked_invoice_number: "Linked Invoice Number",
    none: "None",
    select_client: "Select Client",
    add_product: "Add your product",
    add_terms: "Add terms and condition",
    save_draft: "Save Draft",
    preview_pdf: "Preview PDF",
    remarks: "Remarks",
    linked_invoice: "Linked Invoice",
    do_inv_mismatch_error: "Delivery Order Quantity is more than invoice quantity, please check linked delivery order",
    current_do: "Current Delivery Order",
    previous_do: "Previous Delivery Order",
    invoice_to_client_message1: "You are about to invoice to client. An email with generated PDF will be sent to client.",
    invoice_to_client_message2: "Once invoiced, you won't be able to change the contents in the future.",
    payment_received_confirmation: "Please confirm all payment received.",
    paid: "Paid",
    manage_invoice: "Manage Invoice",
    invoice_date: "Invoice Date",
    resend_email: "Resend Email",
    generate_delivery_order: "Generate Delivery Order",
    create_invoice: "Create Invoice",
    payment_due_date: "Payment Due Date",
    total_amount: "Total Amount",
    invoice_saved: "Invoice Saved.",
    finalise_invoice_message: "You are finalizing this delivery order, please confirm if all information is correct before finalise this order.",
    enable_inv_trans: "Enable Inventory Transaction",
    enable_inv_trans_msg: "Enable this option so that inventory transaction will be generated.",
    invoice_multi_currency_error: "One invoice only allows one currency!",

    //master
    client_Name: "Client Name",
    client_short_Name: "Client Short Name",
    manage_client: "Manage Client",
    manage_product: "Manage Product",
    manage_category: "Manage Category",
    manage_uom: "Manage Uom",
    manage_currency: "Manage Currency",
    manage_terms: "Manage Terms",
    manage_term_type: "Manage Term Type",
    supplier_Name: "Supplier Name",
    supplier_short_Name: "Supplier Short Name",
    manage_supplier: "Manage Supplier",
    communication_type: "System Communication Type",

    //inventory
    manage_product_inventory: "Manage Product Inventory",
    number_more_than_zero_error: "Quantity should be more than Zero",
    inventory_adjustment: "Inventory Adjustment",
    in: "in",
    out: "out",
    adjust: "adjust",
    transaction_added: "Transaction Added.",
    view_product_inventory: "View Product Inventory",
    search_product_code: "Search Product Code",

    //settings
    platform_setting: "Platform Setting",
    document_language: "Document Language",
    english: "English",
    chinese: "Chinese",
    language_setting_message: "Language used for generated PDF.",
    order_setting: "Order Setting",
    invoice_order_format: "Invoice Order Format",
    preview: "Preview",
    delivery_order_format: "Delivery Order Format",
    sequence: "Sequence",
    pdf_format: "PDF Format",
    style_1: "Format 1",
    style_2: "Format 2",
    attention: "Attention",
    save_setting_message: 'Saving with a different invoice format will reset the order sequence, causing inconsistent records in the invoice order.',

    //User Profile
    user_info_updated: "User Info Updated.",
    image_updated: "Image Updated.",
    image_remove: "Image Removed.",
    password_updated: "Password Updated.",
    password_strength_warning: "Password strength too low.",
    file_size_too_big_error: "File size too Big. Please use image smaller than 2Mb.",
    remove_image_prompt: "You are about to remove image, please confirm.",
    change_password: "Change Password",
    change_password_google_message: "*for google login user, you can use forget password function to create a new password",
    old_password: "Old Password",
    new_password: "New Password",
    password_requirement: "Invalid password format.(Minimum 8 characters, with mix of Numbers/Upper/Lower/Special character)",
    update_user_info: "Update User Info",
    user_profile: "User Profile",
    verifed: "Verifed",
    recent_login_on: "Recent Login on",
    login_count: "Login Count",

    //manage company
    user_invited: "User Invited.",
    user_removed: "User Removed.",
    user_removed_message: "You are about to remove user from tenant, please confirm.",
    remove: "remove",
    invite_user: "Invite User",
    invite_user_message: "*An email will be sent to this email address, user will join the company once accepted the invitation",
    invite: "Invite",
    password: "Password",
    user: "USER",
    tenant_admin: "TENANT ADMIN",
    remove_user_from_tenant: "Remove User From Tenant",
    update_company_info: "Update Company Info",
    owner: "Owner",
    owner_firstname: "Owner First Name",
    owner_lastname: "Owner Last Name",
    company_info: "Company Info",
    upload_company_logo: "Upload Company Logo",
    owner_email: "Owner Email",
    introduction: "Introduction",
    subscription_cancelled: "Subscription Cancelled.",
    subscription_cancel_message: "You are about to cancel the tenant subscription.",
    company_subscription: "Company Subscription",
    subscribe: "Subscribe",
    extend_expiry_date: "Extend Expiry Date",
    expire_date: "Expire Date",
    health: "Health",
    payment_provider: "Payment Provider",
    payment_mode: "Payment Mode",
    one_time_payment: "One Time Payment",
    subscription: "Subscription",
    recurring_payment_status: "Recurring Payment Status",
    cancel_subscription: "Cancel Subscription",
    payment_date: "Payment Date",
    customer_email: "Customer Email",
    customer_id: "Customer Id",
    subscription_id: "Subscription Id",
    monthly: "Monthly",
    transaction_history: "Transaction History",
    success: "Success",

    platform_announcement: "Platform Announcement",
    view: "View",

    // -------------------------
    default_role: "Default Role",
    view_supplier: "View Supplier",
    department: "Department",
    manage_department: "Manage Department",

    //Purchase Order
    purchase_order: "Purchase Order",
    purchase_order_format: "Purchase Order Format",
    manage_purchase_order: "Manage Purchase Order",
    create_purchase_order: "Create Purchase Order",
    request_delivery_date: "Request Delivery Date",
    delivery_schedule: "Delivery Schedule",
    generate: "Generate",
    address: "Address",
    generate_po_message: "Purchase order for each supplier will be generated.",
    price: "Price",
    order_send_email: "Order & Send Email",
    created: "Created",
    order_from: "Order From",
    po_to: "PO To",
    deliver_to: "Deliver To",
    total_product: "Total Product",
    receive_all: "Receive All",
    quantity_mismatch: "Quantity Mismatch",
    confirm_line_message: "You are about to confirm receipt of the goods.",
    po_quantity: "Order Quantity.",
    received_quantity: "Received Quantity.",
    no_proof_error_message: "Please upload proof of delivery.",
    no_confirm_item_error_message: "Please confirm receipt of the items.",
    receive_remark: "Received Remark.",
    confirm_purchase_order: "Confirm and APPROVE the purchase item from the supplier. Purchase Order will be EMAIL to supplier",
    finalise_po_message: "You are finalizing this purchase order, please confirm if all information is correct before finalise this order.",
    suppliername: "Supplier",
    default: "Default",
    stock_take: "Stock Take",
    new_stock_take: "New Stock Take",
    for_month: "For Month",
    cut_off_date: "Cut Off Date",
    finalise_stock_take_message: "You are finalizing this stock take, please confirm if all information is correct before proceed.",
    month: "Month",
    prepared_by: "Prepared By",
    adjust_inventory: "Adjust Inventory",
    approve_stock_take_message: "You are APPROVING this stock take, please confirm if all information is correct before proceed.",
    adjust_stock_take_inv_message: "You are about to proceed with the stock take inventory adjustment. Please confirm that all information is correct before continuing.",
    inventory_during_stock_take: "Inventory during Stock Take",
    current_inventory: "Current Inventory",
    no_adjustment_required: "No Adjustment Required",
    no_adjustment_required_message: "You are about to close the inventory adjustment process with no adjustments required. Please confirm that no further changes are needed before proceeding with the closure.",
    adjustment: "Adjustment",
    download: "download",
    completed: "Completed",
    st_inv: "st_inv",
    inv_now: "inv_now",
    stocktake: "stocktake",
    confirm: "confirm",
    approve: "Approve",
    open: "Open",
    cancelled: "Cancelled",
    done: "Done",
    add: "Add",
    view_purchase_order: "View Purchase Order",
    recnote: "recnote",
    stocktakedate: "stocktakedate",
    cutoffdate: "cutoffdate",
    preparedby: "preparedby",
    event: "Event",
    dept: "Dept",
    save: "Save",
    products: "Products",
    finalise: "Finalise",

    pending_approval: "Need Approval",
    pending_adjustment: "Need Adjust",
    confirm_po_received: "Please confirm that all received goods match the quantities specified in the Purchase Order.",
    confirm_po_received_all: "Please confirm that all received goods match the quantities specified in the Purchase Order.",

    purchase: "Purchase",
    sales: "Sales",

    upgrade_to_basic: "Upgrade to the Basic Package",
    free: "Free",
    basic: "Basic",
    mobile: "Mobile",
    portal: "Portal",
    subscription_intro_msg_1: "Manage master data, configuration, and company profile",
    subscription_intro_msg_2: "View invoices and delivery orders",
    subscription_intro_msg_3: "Edit invoices and delivery orders",
    subscription_intro_msg_4: "Manage master data, configuration, and company profile",
    subscription_intro_msg_5: "View invoices and delivery orders",
    subscription_intro_msg_6: "Edit invoices, delivery orders, and purchase orders",
    subscription_intro_msg_7: "Choose from various invoice and delivery order templates featuring your company logo",
    subscription_intro_msg_8: "View and manage your inventory",
    subscription_intro_msg_9: "Receive support from our specialists",

    monthly_payment: "Pay Monthly",
    manager: "Manager",
    no_item_warning: "No items have been added to the list. Please add at least one item.",
    auto_approve_po: "Auto Approve Purchase Order",
    auto_approve_stock_take: "Auto Approve Stock Take",
    platform_notification: "Platform Notification",

    monthly_report: "Monthly Report",
    inventory_control: "Inventory Control",
    reporting: "Reporting",
    from_date: "From Date",
    end_date: "End Date",
    total_price: "Total Price",
    close_date: "Close Date",
    po_history: "PO History",

    monthly_reconsolitation: "Monthly Reconsolitation",
    inventory_snapshot: "Inventory Snapshot",

    user_role_description: "User: Receive PO, manage invoices/DO.",
    manager_role_description: "Manager: Create/Receive PO, manage invoices/DO.",
    admin_role_description: "Admin: Full access to PO, Invoices, DO, Inventory, Reports, etc.",
    role_assignment: "Role Assignment: Add 'Manager' role for managers, 'Admin' for full access.",

    invitation_code:"Invitation Code",
    test_email_msg:"Test Supplier Email",
    please_save_first:"Please save supplier first",

    enable_tax:"Enable Tax",
    tax_rate:"Tax Rate",
    show_all_product_in_stock_take:"Show all product in stock take",

}